import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralEcommercePage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEcommercePage'))
);

// DASHBOARD: Movements
export const MovementShopPage = Loadable(
  lazy(() => import('../pages/movements/MovementShopPage'))
);
export const MovementDetailsPage = Loadable(
  lazy(() => import('../pages/movements/MovementDetailsPage'))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/user/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/user/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/user/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/user/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/user/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/user/UserEditPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/PermissionDeniedPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
